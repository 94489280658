var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', {
    staticClass: "mt-4 create-new"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('router-link', {
    staticClass: "btn btn-sm btn-default",
    attrs: {
      "to": {
        name: _vm.PublisherManagePath.name
      }
    }
  }, [_c('em', {
    staticClass: "fa fa-backward"
  }), _vm._v(" Manage Publisher ")])], 1), _c('b-col', [_c('b-form', {
    on: {
      "submit": _vm.onSubmit,
      "reset": _vm.onReset
    }
  }, [_c('div', {
    staticClass: "card mt-4"
  }, [_c('div', {
    staticClass: "bg-success p-3"
  }, [_c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v(" Add Access New Publisher ")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-card', {
    staticClass: "bg-info text-dark"
  }, [_c('p', [_vm._v("(*) Kolom berlabel bintang wajib diisi.")]), _vm._v("Silahkan cek username terlebih dahulu.")])], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email *"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "email",
      "required": ""
    },
    on: {
      "blur": _vm.validateEmail
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), !this.validateEmail() ? _c('p', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(this.errors.email) + " ")]) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "lg": "6"
    }
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("Username *")]), _c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "search",
      "placeholder": "Type username"
    },
    on: {
      "input": _vm.validateUsername,
      "change": _vm.setQuerySearchUsername
    },
    model: {
      value: _vm.query_search_username,
      callback: function ($$v) {
        _vm.query_search_username = $$v;
      },
      expression: "query_search_username"
    }
  })], 1), _vm.hasSpace ? _c('div', {
    staticClass: "d-block text-danger pt-3"
  }, [_vm._v("Username tidak boleh mengandung spasi.")]) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "lg": "6"
    }
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("Password *")]), _c('div', {
    staticClass: "input-group"
  }, [_vm.showPassword ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.password,
      expression: "form.password"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "inlineFormInputGroup",
      "placeholder": "*********",
      "maxlength": "100",
      "min": "8"
    },
    domProps: {
      "value": _vm.form.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "password", $event.target.value);
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.password,
      expression: "form.password"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "password",
      "id": "inlineFormInputGroup",
      "placeholder": "*********",
      "maxlength": "100",
      "min": "8"
    },
    domProps: {
      "value": _vm.form.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "password", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('div', {
    staticClass: "input-group-text",
    on: {
      "click": _vm.toggleShow
    }
  }, [_c('i', {
    class: {
      'fa fa-eye': _vm.showPassword,
      'fa fa-eye-slash': !_vm.showPassword
    }
  })])])])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Organization *"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "text",
      "track-by": "value",
      "placeholder": "type organization name",
      "open-direction": "top",
      "options": _vm.organizationSearchOptions,
      "searchable": true,
      "loading": _vm.isOrganizationSearch,
      "close-on-select": true,
      "clear-on-select": false,
      "preserve-search": false,
      "preselect-first": false,
      "multiple": false,
      "taggable": false,
      "required": ""
    },
    on: {
      "search-change": _vm.organizationFind,
      "input": _vm.organizationChange
    },
    model: {
      value: _vm.form.organization,
      callback: function ($$v) {
        _vm.$set(_vm.form, "organization", $$v);
      },
      expression: "form.organization"
    }
  })], 1)], 1)], 1), _c('br'), _vm.form.organization ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Publisher *"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "id",
      "placeholder": "Publisher",
      "open-direction": "top",
      "options": _vm.publisherSearchOptions,
      "searchable": true,
      "loading": _vm.isPublisherSearch,
      "close-on-select": true,
      "clear-on-select": false,
      "preserve-search": false,
      "preselect-first": false,
      "multiple": false,
      "taggable": false,
      "required": ""
    },
    model: {
      value: _vm.form.publisher,
      callback: function ($$v) {
        _vm.$set(_vm.form, "publisher", $$v);
      },
      expression: "form.publisher"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('br'), _vm.form.publisher ? _c('b-row', [_c('b-col', [_c('b-button', {
    attrs: {
      "disabled": _vm.hasSpace,
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm._v("Add Access")])], 1)], 1) : _vm._e()], 1)])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<b-overlay
		:show="isLoading"
		rounded="lg"
		opacity="0.6"
		spinner-variant="primary"
	>
		<template #overlay>
			<div class="d-flex align-items-center">
				<b-spinner small type="grow" variant="secondary"></b-spinner>
				<b-spinner type="grow" variant="dark"></b-spinner>
				<b-spinner small type="grow" variant="secondary"></b-spinner>
			</div>
		</template>
		<b-row class="mt-4 create-new">
			<b-col cols="12">
				<router-link class="btn btn-sm btn-default" :to="{name: PublisherManagePath.name}">
					<em class="fa fa-backward"></em> Manage Publisher
				</router-link>
			</b-col>
			<b-col>
        <b-form @submit='onSubmit' @reset='onReset'>
					<div class="card mt-4">
						<div class="bg-success p-3">
							<h5 class="card-title mb-0 font-weight-bold">
								Add Access New Publisher
							</h5>
						</div>
						<div class="card-body">
							<b-row>
								<b-col lg="6" class="mt-4">
									<b-card class="bg-info text-dark"><p>(*) Kolom berlabel bintang wajib diisi.</p>Silahkan cek username terlebih dahulu.</b-card>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6" class="mt-4">
									<b-form-group label="Email *">
										<b-form-input
											id="email"
											v-model="form.email"
											@blur="validateEmail"
											required
										>
										</b-form-input>
									</b-form-group>
									<p v-if="!this.validateEmail()" class="text-danger">
										{{this.errors.email}}
									</p>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6" class="mt-4">
									<label for="email">Username *</label>
									<b-input-group>
										<b-form-input
											v-model="query_search_username"
											type="search"
											@input="validateUsername"
											placeholder="Type username"
											@change="setQuerySearchUsername"
										></b-form-input>
									</b-input-group>
									<div  v-if="hasSpace" class="d-block text-danger pt-3">Username tidak boleh mengandung spasi.</div>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6" class="mt-4">
									<label for="email">Password *</label>
									<div class="input-group">
									<input v-if="showPassword" type="text" class="form-control" id="inlineFormInputGroup" placeholder="*********" maxlength="100" v-model="form.password" min="8">
									<input v-else type="password" class="form-control" id="inlineFormInputGroup" placeholder="*********" maxlength="100" v-model="form.password" min="8">
									<div class="input-group-prepend">
										<div class="input-group-text" @click="toggleShow">
										<i :class="{ 'fa fa-eye': showPassword, 'fa fa-eye-slash': !showPassword }"></i>
										</div>
									</div>
									</div>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6" class="mt-4">
									<b-form-group label="Organization *">
										<multiselect v-model="form.organization" label="text" track-by="value" placeholder="type organization name" open-direction="top" :options="organizationSearchOptions" :searchable="true" :loading="isOrganizationSearch" :close-on-select="true" :clear-on-select="false" :preserve-search="false" :preselect-first="false" :multiple="false" :taggable="false" @search-change="organizationFind" @input="organizationChange" required></multiselect>
									</b-form-group>
								</b-col>
							</b-row>
							<br />
							<b-row v-if="form.organization">
								<b-col lg="6">
									<b-form-group label="Publisher *">
										<multiselect v-model="form.publisher" label="name" track-by="id" placeholder="Publisher" open-direction="top" :options="publisherSearchOptions" :searchable="true" :loading="isPublisherSearch" :close-on-select="true"  :clear-on-select="false" :preserve-search="false" :preselect-first="false" :multiple="false" :taggable="false" required></multiselect>
									</b-form-group>
								</b-col>
							</b-row>
							<br />
							<b-row v-if="form.publisher">
								<b-col>
									<b-button :disabled="hasSpace" variant="primary" type="submit">Add Access</b-button>
								</b-col>
							</b-row>
						</div>
					</div>
				</b-form>
			</b-col>
		</b-row>
	</b-overlay>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { PublisherManagePath } from "../../router/account";

export default {
	name: "newPublisher",
	data() {
		return {
			PublisherManagePath,
			query_search_username: '',
			showPassword:false,
			debounce: null,
			hasSpace:false,
			form: {
				id: null,
				password: null,
				email:null,
				organization: null,
				publisher: null,
			},
			isUsernameSearch: false,
			isOrganizationSearch: false,
			organizationSearchOptions: [],
			isPublisherSearch: false,
			errors: {},
			publisherSearchOptions: [],
		};
	},
	computed: {
		...mapState({
			isError: (state) => state.accessPublisher.isError,
			isLoading: (state) => state.accessPublisher.isLoading,
			errorMessage: (state) => state.accessPublisher.errorMessage,
			successMessage: (state) => state.accessPublisher.successMessage,
			item: (state) => state.accessPublisher.item
		})
	},
	watch: {
		errorMessage: function() {
			if (!this.errorMessage) return;
			this.messageAlert("error", this.errorMessage, 5000);
		},
		successMessage: function() {
			if (!this.successMessage) return;
			this.messageAlert("success", this.successMessage);

			Object.assign(this.$data, this.$options.data.call(this));
			this.$router.push(PublisherManagePath);
		}
	},
	created() {},
	methods: {
		...mapActions("accessPublisher", ["postPublisher", "fetchPublisherByUsername"]),
		...mapActions("organization", {
			AutocompleteSearch: "AutocompleteSearch"
		}),
		...mapActions("vendors", ["AutocompleteVendorByOrganizationId"]),
		toggleShow() {
			this.showPassword = !this.showPassword;
		},
		validateUsername() {
			if ( this.query_search_username && this.query_search_username.includes(' ')) {
				this.hasSpace = true;
			} else {
				this.hasSpace = false;
			}
			},
		onSubmit(event) {
			event.preventDefault();
			this.errors = {};
			if (!this.validateEmail()) {
				this.errors.email = 'Invalid email';
			}
			if (Object.keys(this.errors).length > 0) {
			return;
			}
			const data = this.form;
			const payload = {
				id: data.id,
				username: this.query_search_username,
				email:data.email,
				password: data.password,
				id_organization: data.organization.value,
				id_vendor: data.publisher?.id ? data.publisher.id : 0,
			};
			this.postPublisher(payload);
		},
		onReset() {},
		messageAlert(icon, title, timer = 3000) {
			this.$swal({
				toast: "true",
				position: "top-end",
				icon,
				title,
				showConfirmButton: false,
				timer,
				timerProgressBar: true,
			});
		},
		setQuerySearchUsername(query) {
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.query_search_username = query;
			}, 1000);
		},
		clearForm() {
			this.form.id = null;
			this.form.password = null;
			this.form.organization = null;
			this.form.publisher = null;
		},
		organizationFind(query) {
			if (!query) return;

			this.isOrganizationSearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.AutocompleteSearch({ q: query })
					.then((response) => {
						this.organizationSearchOptions = response.data.data.rows;
						this.isOrganizationSearch = false;
					})
					.catch(() => {
						this.isOrganizationSearch = false;
					});
			}, 1200);
		},
		organizationChange() {
			this.publisherFind();
		},
		validateEmail() {
			return /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.email);
		},
		publisherFind() {
			this.isPublisherSearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				const idOrganization = this.form.organization.value;
				this.AutocompleteVendorByOrganizationId({ id: idOrganization })
					.then((response) => {
						this.publisherSearchOptions = response.data.data.rows;
						if (this.publisherSearchOptions.length > 0) {
							this.form.publisher = this.publisherSearchOptions[0];
						}
						this.isPublisherSearch = false;
					})
					.catch(() => {
						this.isPublisherSearch = false;
						this.form.publisher = null; // Clear the publisher if the fetch fails or no publishers are found
					});
			}, 1200);
		},
	},
};
</script>
